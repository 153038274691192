import { LoadingSpinner } from "@clipboard-health/ui-components";
import { mergeSxProps } from "@clipboard-health/ui-react";
import { Stack, type StackProps } from "@mui/material";

import { IconButton } from "../../components/IconButton";

interface MapControlsProps {
  canCenterToHome: boolean;
  canCenterToDevice: boolean;
  isLoading: boolean;
  onZoomIn: () => void;
  onZoomOut: () => void;
  onCenterToHome: () => void;
  onCenterToDevice: () => void;
  children?: React.ReactNode;
}

function ControlsWrapper(props: StackProps) {
  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="center"
      {...props}
      sx={mergeSxProps(
        (theme) => ({
          backgroundColor: theme.palette.background.tertiary,
          borderRadius: theme.borderRadius?.xLarge,
          boxShadow: theme.shadows[2],
          position: "absolute",
          top: theme.spacing(4),
        }),
        props.sx
      )}
    />
  );
}

export function ShiftDiscoveryMapViewMapControls(props: MapControlsProps) {
  const {
    canCenterToHome,
    canCenterToDevice,
    isLoading,
    onZoomIn,
    onZoomOut,
    onCenterToHome,
    onCenterToDevice,
    children,
  } = props;

  return (
    <Stack sx={{ position: "absolute", top: 0, left: 0, right: 0 }}>
      {children}

      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        marginTop={4}
        sx={{ position: "absolute", bottom: 0, left: 0, right: 0 }}
      >
        {(canCenterToHome || canCenterToDevice) && (
          <ControlsWrapper
            sx={(theme) => ({
              left: theme.spacing(4),
            })}
          >
            {canCenterToHome && (
              <IconButton invert iconType="house" variant="contained" onClick={onCenterToHome} />
            )}
            {canCenterToDevice && (
              <IconButton
                invert
                iconType="navigation-arrow"
                variant="contained"
                onClick={onCenterToDevice}
              />
            )}
          </ControlsWrapper>
        )}

        {isLoading && (
          <ControlsWrapper
            sx={(theme) => ({
              width: theme.size?.iconButton.medium.size,
              height: theme.size?.iconButton.medium.size,
              // center
              left: "50%",
              transform: "translateX(-50%)",
            })}
          >
            <LoadingSpinner size="small" variant="primary" />
          </ControlsWrapper>
        )}

        <ControlsWrapper sx={(theme) => ({ right: theme.spacing(4) })}>
          <IconButton invert iconType="minus" variant="contained" onClick={onZoomOut} />
          <IconButton invert iconType="plus" variant="contained" onClick={onZoomIn} />
        </ControlsWrapper>
      </Stack>
    </Stack>
  );
}
