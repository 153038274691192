import { isDefined } from "@clipboard-health/util-ts";
import { convertToGoogleMapsLocation } from "@src/appV2/Location/utils";

import { MAX_MAP_ZOOM } from "../constants";
import { getBoundsAroundLatLng } from "./getBoundsAroundLatLng";
import type { MapViewWorkplaceForClustering, WorkplaceCluster, WorkplaceMarker } from "./types";

/**
 * The size of the marker in pixels.
 * Used to calculate the bounds around a marker,
 * and check if other markers intersect with it.
 * Doesn't need to be exact.
 *
 * Use debug mode on WorkplaceClusterer to visualize the bounds.
 */
const MARKER_WIDTH = 40;
const MARKER_HEIGHT = 40;

/**
 * Build a unique id for a cluster based on its set of markers.
 */
function buildClusterId(workplaceMarkers: WorkplaceMarker[]) {
  return workplaceMarkers
    .map((marker) => marker.workplace.id)
    .sort()
    .join("-");
}

function calculateMarkerBounds(marker: WorkplaceMarker, map: google.maps.Map) {
  return getBoundsAroundLatLng(map, marker.position, MARKER_WIDTH, MARKER_HEIGHT);
}

function calculateClusterBounds(cluster: WorkplaceCluster, map: google.maps.Map) {
  const clusterBounds = new google.maps.LatLngBounds();
  cluster.workplaceMarkers.forEach((marker) => {
    const bounds = calculateMarkerBounds(marker, map);
    if (bounds) {
      clusterBounds.union(bounds);
    }
  });
  return clusterBounds;
}

interface BuildWorkplaceClustersProps {
  map: google.maps.Map;
  workplaces: MapViewWorkplaceForClustering[];
  enabled?: boolean;
  zoom?: number;
}

export function buildWorkplaceClusters(props: BuildWorkplaceClustersProps) {
  const { map, workplaces, enabled = true } = props;
  const currentZoom = map.getZoom() ?? 0;

  // If user can't zoom in more, show all workplaces
  const shouldCluster = enabled && currentZoom < MAX_MAP_ZOOM;

  const workplaceMarkers: WorkplaceMarker[] = workplaces
    .filter((workplace) => isDefined(workplace.attributes.location))
    .map((workplace) => {
      const coordinates = convertToGoogleMapsLocation(workplace.attributes.location);
      const position = new google.maps.LatLng(coordinates);
      const bounds = calculateMarkerBounds({ workplace, position }, map);
      return {
        workplace,
        position,
        bounds,
      };
    });

  const workplaceClusters: WorkplaceCluster[] = [];

  workplaceMarkers.forEach((workplaceMarker) => {
    const { bounds, position, workplace } = workplaceMarker;

    if (!bounds || !shouldCluster) {
      workplaceClusters.push({
        id: buildClusterId([workplaceMarker]),
        position,
        bounds,
        workplaceMarkers: [workplaceMarker],
        totalShifts: workplace.shiftsCount,
      });
      return;
    }

    const cluster = workplaceClusters.find((cluster) => cluster.bounds?.intersects(bounds));

    // If a existing cluster was found, add the workplace to it and
    // re-center the cluster in the middle of its workplaces
    if (cluster) {
      cluster.workplaceMarkers.push(workplaceMarker);
      cluster.workplaceMarkers.sort((a, b) => a.workplace.shiftsCount - b.workplace.shiftsCount);
      cluster.totalShifts += workplace.shiftsCount;
      cluster.bounds = calculateClusterBounds(cluster, map);
      cluster.position = cluster.bounds.getCenter();
      cluster.id = buildClusterId(cluster.workplaceMarkers);
      return;
    }

    // If no existing cluster was found, create a new one for this workplace.
    // In the UI, unless other workplaces are added to this cluster, it will be
    // shown as a single marker.
    workplaceClusters.push({
      id: buildClusterId([workplaceMarker]),
      position,
      bounds,
      workplaceMarkers: [workplaceMarker],
      totalShifts: workplace.shiftsCount,
    });
  });

  // Put clusters with more shifts to the front
  return workplaceClusters.sort((a, b) => a.totalShifts - b.totalShifts);
}
