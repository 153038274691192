import { Card, CbhIcon } from "@clipboard-health/ui-components";
import { Text } from "@clipboard-health/ui-react";
import { CardContent, Fade, Stack } from "@mui/material";

import {
  SHIFT_DISCOVERY_SEARCH_MODE_SWITCHER_SPACING_BOTTOM,
  SHIFT_DISCOVERY_SEARCH_MODE_SWITCHER_SPACING_HEIGHT,
} from "../SearchModeSwitcher";

interface ShiftDiscoveryMapViewEmptyStateCardProps {
  isVisible: boolean;
}

export function ShiftDiscoveryMapViewEmptyStateCard(
  props: ShiftDiscoveryMapViewEmptyStateCardProps
) {
  const { isVisible } = props;

  return (
    <Fade in={isVisible} timeout={500}>
      <Card
        elevation={3}
        sx={(theme) => ({
          borderRadius: theme.borderRadius?.small,
          position: "absolute",
          left: theme.spacing(7),
          right: theme.spacing(7),
          // Make sure the card is above the search mode switcher
          bottom: `calc(
          ${theme.spacing(6)} +
          ${theme.spacing(SHIFT_DISCOVERY_SEARCH_MODE_SWITCHER_SPACING_HEIGHT)} +
          ${theme.spacing(SHIFT_DISCOVERY_SEARCH_MODE_SWITCHER_SPACING_BOTTOM)}
        )`,
        })}
      >
        <CardContent>
          <Stack direction="row" alignItems="center" spacing={2}>
            <CbhIcon type="search" />
            <Stack>
              <Text
                variant="caption"
                letterSpacing="inherit"
                color={(theme) => theme.palette.text?.secondary}
              >
                The are currently no open shifts in this area.
              </Text>
              <Text
                variant="caption"
                letterSpacing="inherit"
                color={(theme) => theme.palette.text?.secondary}
              >
                Move the map to see more options
              </Text>
            </Stack>
          </Stack>
        </CardContent>
      </Card>
    </Fade>
  );
}
